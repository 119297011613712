<template>
  <v-card-text>
    <MasterElement>
      <ScrollShadowElement>
        <v-card>
          <v-card-text>
            <v-row class="my-2">
              <v-col cols="12">
                <the-load-file
                  :value="value"
                  :multiple="false"
                  path="profile/avatar"
                  @input="(e) => $emit('input', e)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ScrollShadowElement>
    </MasterElement>
  </v-card-text>
</template>

<script>
export default {
  name: "ChangeAvatarContent",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  // methods: {
  //   async save() {
  //     let data = JSON.parse(JSON.stringify(this.request))
  //     let res = await this.$axios.patch("profile/change-avatar", data)
  //     if (res && res.status && res.status === 200) {
  //       this.$store.dispatch("setAlert", {
  //         type: "success",
  //       })
  //       this.$emit("input", false)
  //       this.$emit("map")
  //     }
  //   },
  // },
}
</script>
