<template>
  <div v-if="!groupLength" class="vertical-drawer">
    <v-row class="ma-0 flex-nowrap" style="height: 64px" align="center">
      <v-app-bar-nav-icon
        class="mr-3 ml-2"
        :class="{ 'v-app-bar__nav-icon-active': drawer }"
        @click="$emit('changeDrawer')"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="12" y="15.5" width="26" height="3" rx="1.5" fill="white" />
          <rect x="12" y="23.5" width="26" height="3" rx="1.5" fill="white" />
          <rect x="12" y="31.5" width="26" height="3" rx="1.5" fill="white" />
        </svg>
      </v-app-bar-nav-icon>
      <TheBrand v-show="!drawer" pr />
    </v-row>
    <ScrollShadowElement style="overflow-y: overlay">
      <v-list-item-group v-for="group in items" :key="group.key" class="group">
        <!-- <v-divider class="hr vertical"></v-divider> -->
        <div
          class="d-flex align-center cursor-pointer"
          :class="{
            'active-for_drawer': checkActive(group),
          }"
        >
          <v-tooltip transition="slide-x-transition" right>
            <template #activator="{ on, attrs }">
              <v-row
                class="flex-nowrap w-full"
                align="center"
                no-gutters
                v-bind="drawer ? attrs : ''"
                v-on="drawer ? on : ''"
                @click.stop="openShow(group)"
              >
                <v-icon size="8" color="primary" class="text--darken-1">
                  mdi-circle
                </v-icon>
                <v-subheader v-show="!drawer" class="pa-0 sub-group">
                  {{ $localize(group.key) }}
                </v-subheader>
                <v-spacer v-show="!drawer" />
                <v-btn
                  v-show="!drawer"
                  icon
                  class="mr-2"
                  :class="{ reverse: group.show }"
                  @click.stop="group.show = !group.show"
                >
                  <v-icon style="font-size: 16px"> mdi-chevron-down </v-icon>
                </v-btn>
              </v-row>
            </template>
            <span> {{ $localize(group.key) }} </span>
          </v-tooltip>
        </div>
        <v-divider class="hr vertical" :inset="!drawer"></v-divider>
        <v-expand-transition>
          <v-list v-show="drawer || group.show" nav dense class="pa-0">
            <v-list-item-group color="white">
              <v-tooltip
                v-for="(item, i) in group.items"
                :key="i"
                transition="slide-x-transition"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-list-item
                    :to="item.path"
                    v-bind="drawer ? attrs : ''"
                    class="pa-0"
                    v-on="drawer ? on : ''"
                  >
                    <v-list-item-icon>
                      <v-icon style="font-size: 20px" v-text="item.meta.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 11px"
                        v-text="$localize(item.name)"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{ $localize(item.name) }}</span>
              </v-tooltip>
            </v-list-item-group>
          </v-list>
        </v-expand-transition>
      </v-list-item-group>
    </ScrollShadowElement>
  </div>
  <div v-else class="vertical-drawer">
    <v-row class="ma-0 flex-nowrap" style="height: 64px" align="center">
      <v-app-bar-nav-icon
        class="mr-3 ml-2"
        :class="{ 'v-app-bar__nav-icon-active': drawer }"
        @click="$emit('changeDrawer')"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="12" y="15.5" width="26" height="3" rx="1.5" fill="white" />
          <rect x="12" y="23.5" width="26" height="3" rx="1.5" fill="white" />
          <rect x="12" y="31.5" width="26" height="3" rx="1.5" fill="white" />
        </svg>
      </v-app-bar-nav-icon>
      <TheBrand v-show="!drawer" pr />
    </v-row>
    <ScrollShadowElement style="overflow-y: overlay">
      <div class="main-navigation-user" :class="{ drawer }">
        <div class="d-flex">
          <ProfileAvatar
            :img="profile ? profile.avatar_path : ''"
            :title="profile.full_name"
            class="mr-5"
          />
          <div v-if="!drawer" class="w-full">
            <span class="title">{{ profile.full_name }}</span>
            <br />
            <span class="sub-title">@{{ profile.username }}</span>
            <div class="d-flex w-full mt-3">
              <v-btn dark @click="openDialog('profile')">
                <v-icon size="18"> mdi-account-outline </v-icon>
              </v-btn>

              <v-btn dark> RU </v-btn>
              <!-- <v-menu>
                <template #activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on"> RU </v-btn>
              </template>
                <v-list>
                  <v-list-item v-for="item in languages" :key="item">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
              <!-- <v-btn dark to="/settings">
                <v-icon size="18"> mdi-cog-outline </v-icon>
              </v-btn> -->
              <v-spacer />
              <v-btn dark @click="logout">
                <v-icon size="18"> mdi-exit-to-app </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-list-item-group color="white" class="w-full">
        <v-tooltip
          v-for="(item, i) in checkMyRoutes"
          :key="i"
          transition="slide-x-transition"
          right
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              :to="item.path"
              class="px-3"
              v-bind="drawer ? attrs : ''"
              v-on="drawer ? on : ''"
            >
              <v-list-item-icon style="margin-left: 6px; margin-right: 12px">
                <v-icon style="font-size: 20px" v-text="item.meta.icon" />
              </v-list-item-icon>
              <v-list-item-content v-show="!drawer">
                <v-list-item-title
                  style="font-size: 11px"
                  v-text="$localize(item.name)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ $localize(item.name) }}</span>
        </v-tooltip>
      </v-list-item-group>
      <v-spacer></v-spacer>
      <v-tooltip v-if="profile.role !== 'CEO'">
        <template #activator="{ on, attrs }">
          <router-link
            :to="{ name: 'complaints' }"
            class="px-3 d-flex"
            style="text-decoration: none"
            v-bind="drawer ? attrs : ''"
            v-on="drawer ? on : ''"
          >
            <v-list-item-icon class="mx-2">
              <v-icon style="font-size: 20px" v-text="'mdi-face-agent'" />
            </v-list-item-icon>
            <v-list-item-content v-show="!drawer">
              <v-list-item-title
                style="font-size: 11px"
                v-text="$localize('complaints')"
              ></v-list-item-title>
            </v-list-item-content>
          </router-link>
        </template>
        {{ $localize("complaints") }}
      </v-tooltip>
    </ScrollShadowElement>
    <EditProfileDialog
      v-if="dialogs.editProfile"
      v-model="dialogs.editProfile"
      :item="profile"
      @map="updateUser()"
    />
    <ProfileDialog
      v-if="dialogs.profile"
      v-model="dialogs.profile"
      :item="profile"
      @edit="openDialog('editProfile')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProfileDialog from "./modules/ProfileDialog"
import EditProfileDialog from "./modules/EditProfileDialog"

export default {
  name: "TheNav",
  components: { EditProfileDialog, ProfileDialog },
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      dialogs: {
        profile: false,
        editProfile: false,
      },
    }
  },
  computed: {
    ...mapGetters("app", { getNav: "getNav" }),
    ...mapGetters("locale", { languages: "langs" }),
    ...mapGetters("app", { profile: "getProfile" }),
    groupLength() {
      return this.items.filter((x) => x.items.length).length == 1
    },
    checkMyRoutes() {
      return this.items[0].items.filter(
        (x) =>
          x.meta.roles &&
          x.meta.roles.includes(this.profile ? this.profile.role : "")
      )
    },
  },
  mounted() {
    this.items = this.getNav.map((x) => ({ ...x, show: true }))
  },
  methods: {
    updateUser() {
      return this.$store.dispatch("app/setProfile")
    },
    openDialog(...keys) {
      Object.keys(this.dialogs).forEach((x) => {
        this.dialogs[x] = !!~keys.indexOf(x)
      })
    },
    logout() {
      return this.$store.dispatch("app/setToken", null)
    },
    openShow(group) {
      if (this.drawer) return
      group.show = !group.show
    },
    checkActive(group) {
      if (!this.drawer && group.show) return false
      return group.items.find((x) => x.path == this.$route.path)
    },
  },
}
</script>

<style lang="scss" scoped>
.active-for_drawer {
  & > * {
    color: white !important;
  }
  &:before {
    background-color: white;
    opacity: 0.12;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s;
  }
}
.reverse {
  transform: scaleY(-1);
}
.v-divider--inset.hr.vertical {
  max-width: calc(100% - 44px);
  margin-left: 44px !important;
}
.group {
  & > * {
    &:first-child {
      min-height: 48px;
    }
  }
  &:nth-last-child(-n + 2) {
    padding-bottom: 1rem;
  }
  & .sub-group {
    font-size: 11px;
    text-transform: uppercase;
  }
  &:nth-child(2) {
    margin-top: 0;
  }
}
</style>
