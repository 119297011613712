import Vue from "vue"
import router from "@/router"

import routes from "@/router/routes"

export default {
  namespaced: true,
  state: {
    activeNavGroup: 0,
    mobileDrawer: false,
    auxilarium: false,
    auxilariumOpen: false,
    subNavigation: false,
    subNavigationOpen: false,
    detail: false,
    detailOpen: false,
    navGroups: [
      {
        key: "all",
        items: [],
      },
    ],
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    profile: null,
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getNav(state) {
      return state.navGroups.filter((g) => {
        return g.items.length > 0
      })
    },
    getMobileDrawer(state) {
      return state.mobileDrawer
    },
    getAuxilarium(state) {
      return state.auxilarium
    },
    getAuxilariumOpen(state) {
      return state.auxilariumOpen
    },
    getSubNavigation(state) {
      return state.subNavigation
    },
    getSubNavigationOpen(state) {
      return state.subNavigationOpen
    },
    getDetail(state) {
      return state.detail
    },
    getProfile(state) {
      return state.profile
    },
    getDetailOpen(state) {
      return state.detailOpen
    },
  },
  actions: {
    init(ctx) {
      ctx.commit("init")
      if (ctx.state.token) {
        ctx.commit("setProfile")
      }
    },
    async setToken(ctx, token) {
      await ctx.commit("setToken", token)
      ctx.commit("setProfile")
      ctx.dispatch("dictionaries/init", {}, { root: true })
    },
    setProfile(ctx) {
      ctx.commit("setProfile")
    },
    setMobileDrawer(ctx) {
      ctx.commit("mutateMobileDrawer")
    },
    setAuxilarium(ctx, val) {
      ctx.commit("mutateAuxilarium", val)
    },
    setAuxilariumOpen(ctx, val) {
      ctx.commit("mutateAuxilariumOpen", val)
    },
    setSubNavigation(ctx, val) {
      ctx.commit("mutateSubNavigation", val)
    },
    setSubNavigationOpen(ctx, val) {
      ctx.commit("mutateSubNavigationOpen", val)
    },
    setDetail(ctx, val) {
      ctx.commit("mutateDetail", val)
    },
    setDetailOpen(ctx, val) {
      ctx.commit("mutateDetailOpen", val)
    },
    setLayoutType(ctx) {
      ctx.commit("mutateLayoutType")
      ctx.commit("init")
    },
    setNavGroupByPath(ctx, val) {
      ctx.commit("mutateNavGroupByPath", val)
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      Vue.prototype.$axios.defaults.headers.common[
        "Authorization"
        // ] = `Bearer ${token}`
      ] = `${token}`
      localStorage.setItem("token", token)
    },
    async setProfile(state) {
      if (state.token)
        Vue.prototype.$axios.defaults.headers.common[
          "Authorization"
        ] = `${state.token}`
      Vue.prototype.$axios
        .get("profile/me")
        .then((res) => {
          if (!res || res.status !== 200) state.profile = state.token = null
          else {
            let profile = res.data
            profile.full_name = (
              (profile.last_name || "") +
              " " +
              (profile.first_name || "")
            ).trim()
            state.profile = profile
          }
        })
        .catch(() => {
          state.profile = state.token = null
          if (!router.app.$route.path.includes("login")) router.push("/login")
        })
    },
    init(state) {
      state.navGroups.forEach((g) => {
        g.items = []
      })

      let all = state.navGroups.find((x) => x.key == "all")
      routes
        .get()
        .filter((x) => x.meta.group && !x.meta.isLogin)
        .forEach((i) => {
          return all.items.push(i)
        })
      console.log("ctx.app: initialized")
    },
    mutateMobileDrawer(state) {
      state.mobileDrawer = !state.mobileDrawer
    },
    mutateAuxilarium(state, val) {
      state.auxilarium = val
    },
    mutateAuxilariumOpen(state, val) {
      state.auxilariumOpen = val
    },
    mutateSubNavigation(state, val) {
      state.subNavigation = val
    },
    mutateSubNavigationOpen(state, val) {
      state.subNavigationOpen = val
    },
    mutateDetail(state, val) {
      state.detail = val
    },
    mutateDetailOpen(state, val) {
      state.detailOpen = val
    },
    mutateNavGroupByPath(state, val) {
      if (!val) return
      const n = routes.get().find((i) => i.path === val)
      if (!n) return (state.activeNavGroup = 0)
      const g = state.navGroups
        .filter((x) => x.items.length)
        .find((i) => i.key === n.meta.group)
      if (!g) return (state.activeNavGroup = 0)
      let idx = state.navGroups.filter((x) => x.items.length).indexOf(g)
      state.activeNavGroup = idx
    },
  },
}
