<template>
  <v-card-text>
    <MasterElement>
      <ScrollShadowElement>
        <v-card>
          <v-card-text>
            <v-row class="my-2">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="value.first_name"
                  :label="$localize('first_name')"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="value.last_name"
                  :label="$localize('last_name')"
                  dense
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ScrollShadowElement>
    </MasterElement>
  </v-card-text>
</template>

<script>
export default {
  name: "MainContent",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
