<template>
  <v-dialog
    :value="value"
    width="100%"
    max-width="850"
    transition="scroll-x-reverse-transition"
    scrollable
    @input="(e) => $emit('input', e)"
    @click:outside="$emit('input', false)"
  >
    <v-card color="bg">
      <v-card-title>
        {{ $localize("edit_profile") }}
        <v-spacer />
        <v-tooltip bottom center>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="d-none d-md-flex"
              icon
              v-on="on"
              @click="$emit('input', false)"
            >
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>{{ $localize("close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-tabs v-model="tab">
        <v-tab v-for="tabItem in tabs" :key="tabItem" :value="tabItem">{{
          $localize(tabItem)
        }}</v-tab>
      </v-tabs>
      <ChangePasswordContent v-if="tab == 1" v-model="requestPassword" />
      <template v-else>
        <ChangeAvatarContent v-model="request.avatar_path" />
        <MainContent v-model="request" @validate="(e) => (valid = !!e)" />
      </template>
      <v-card-actions class="d-flex flex-wrap">
        <v-row class="ma-0">
          <v-spacer />
          <v-btn
            class="mr-2"
            color="gray_3"
            text
            @click="$emit('input', false)"
          >
            {{ $localize("cancel") }}
          </v-btn>
          <v-btn color="primary" :disabled="!valid" @click="save">
            {{ $localize("save") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MainContent from "./MainContent"
import ChangeAvatarContent from "./ChangeAvatarContent"
import ChangePasswordContent from "./ChangePasswordContent"
export default {
  name: "EditDialog",
  components: { MainContent, ChangeAvatarContent, ChangePasswordContent },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: ["main_content", "change_password_content"],
      tab: "main_content",
      request: JSON.parse(JSON.stringify(this.item)),
      requestPassword: {
        password: "",
        repeatPassword: "",
      },
    }
  },
  computed: {
    valid() {
      if (this.tab == 1) return this.validPassword
      return this.validMainContent
    },
    validMainContent() {
      return !!this.request.first_name && !!this.request.last_name
    },
    validPassword() {
      return (
        !!this.requestPassword.password &&
        this.requestPassword.password.length > 3 &&
        this.requestPassword.password === this.requestPassword.repeatPassword
      )
    },
  },
  watch: {
    async "request.avatar_path"(newData) {
      let data = { avatar_path: newData }
      let res = await this.$axios.patch("profile/change-avatar", data)
      if (res && res.status && res.status === 200) {
        this.$store.dispatch("setAlert", {
          type: "success",
        })
        this.$emit("map")
      }
    },
  },
  methods: {
    async save() {
      if (this.tab == 1) await this.changePassword()
      await this.changeMainInfo()
    },
    async changePassword() {
      if (!this.validPassword) return
      let data = {
        password: (this.requestPassword.password + "").trim(),
      }
      let res = await this.$axios.patch(`profile/change-password`, data)
      if (res && res.status && res.status === 200) {
        this.$store.dispatch("setAlert", {
          type: "success",
        })
        this.$emit("input", false)
        this.$emit("map")
      }
    },
    async changeMainInfo() {
      if (!this.validMainContent) return
      let data = {
        first_name: (this.request.first_name + "").trim(),
        last_name: (this.request.last_name + "").trim(),
        telegram_id: this.request.telegram_id,
      }
      let res = await this.$axios.put(`profile/edit`, data)
      if (res && res.status && res.status === 200) {
        this.$store.dispatch("setAlert", {
          type: "success",
        })
        this.$emit("input", false)
        this.$emit("map")
      }
    },
  },
}
</script>
