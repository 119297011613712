<template>
  <v-card-text>
    <MasterElement>
      <ScrollShadowElement>
        <v-card>
          <v-card-text>
            <v-row class="my-2">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="value.password"
                  :label="$localize('password')"
                  :type="isShowPassword ? 'text' : 'password'"
                  dense
                  hide-details
                  outlined
                >
                  <template #append>
                    <v-icon @click="isShowPassword = !isShowPassword">
                      {{ isShowPassword ? "mdi-eye-remove" : "mdi-eye" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="value.repeatPassword"
                  :label="$localize('repeat_password')"
                  :type="isShowRepeatPassword ? 'text' : 'password'"
                  dense
                  hide-details
                  outlined
                >
                  <template #append>
                    <v-icon
                      @click="isShowRepeatPassword = !isShowRepeatPassword"
                    >
                      {{ isShowRepeatPassword ? "mdi-eye-remove" : "mdi-eye" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ScrollShadowElement>
    </MasterElement>
  </v-card-text>
</template>

<script>
export default {
  name: "ChangePasswordContent",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowPassword: false,
      isShowRepeatPassword: false,
    }
  },
}
</script>
