<template>
  <v-row
    v-if="!isInited"
    class="vertical ma-0"
    style="min-height: 100vh; background-color: var(--v-notification-base)"
    align="center"
    justify="center"
  >
    <v-row class="ma-0 flex-column" align="center" justify="center">
      <v-row class="ma-0 flex-column" align="center" justify="start">
        <img
          src="@/assets/long_LogoTRAFFKILLAS_black.png"
          style="height: 20px"
          alt="logo"
        />

        <v-progress-circular
          class="mt-4"
          size="27"
          color="nav"
          indeterminate
          value="180"
        />
      </v-row>
      <v-progress-circular
        :size="50"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-row>
  <v-app v-else>
    <v-navigation-drawer
      app
      permanent
      :mini-variant.sync="drawer"
      color="navigation"
      width="256px"
      class="d-md-flex"
      :class="{ 'd-none': !drawer_mobile, 'd-flex': drawer_mobile }"
      dark
    >
      <the-nav :drawer="drawer" @changeDrawer="drawer = !drawer"></the-nav>
    </v-navigation-drawer>
    <v-app-bar class="vertical pa-0" color="bg" elevation="0" app>
      <v-app-bar-nav-icon
        class="d-flex d-md-none"
        :class="{
          'v-app-bar__nav-icon-active': drawer_mobile,
          'v-app-bar__nav-icon-active reverse':
            (getSubNavigation && !getSubNavigationOpen) ||
            (getDetail && getDetailOpen),
        }"
        @click="openDrawer()"
      >
        <svg
          v-if="getSubNavigation || getDetailOpen"
          width="40"
          height="40"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="12" y="15.5" width="26" height="3" rx="1.5" fill="black" />
          <rect x="12" y="23.5" width="26" height="3" rx="1.5" fill="black" />
          <rect x="12" y="31.5" width="26" height="3" rx="1.5" fill="black" />
        </svg>
      </v-app-bar-nav-icon>
      <v-card-title class="pa-0 ma-0 d-flex flex-row align-center">
        {{ $localize($route.matched[0].name) }}
        <template v-if="secondaryNavs && secondaryNavs.length">
          <v-breadcrumbs :items="secondaryNavs" divider=">" large>
            <template #item="{ item }">
              <v-breadcrumbs-item
                :disabled="
                  secondaryNavs.findLast((x) => x.status).title === item.title
                "
                @click="goToElem(item)"
              >
                {{ item.title }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </template>
      </v-card-title>
      <v-spacer />
      <!-- Profile -->
      <!-- <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" class="mr-6 d-flex flex-column" v-on="on">
            <static-title> {{ profile.username }} </static-title>
            <static-text>
              {{ profile.role }}
            </static-text>
            <static-subtext>
              {{ $moment(date).format("DD.MM.YY HH:mm") }}
            </static-subtext>
          </div>
          <img
            v-if="profile.avatar_path"
            :src="profile.avatar_path"
            width="50"
            class="mx-2"
          />
          <v-icon v-else size="50" class="mx-2">
            mdi-account-circle-outline
          </v-icon>
        </template>
        <v-list class="pa-0">
          <v-list-item :to="{ name: 'profile' }">
            <v-list-item-title>
              <v-icon>mdi-account-circle-outline</v-icon>
              {{ $localize("profile") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>
              {{ $localize("logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-btn
        v-if="getAuxilarium"
        class="ml-2"
        icon
        @click="$store.dispatch('app/setAuxilariumOpen', true)"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
      <!-- <the-account /> -->
    </v-app-bar>
    <v-main :class="{ drawer }">
      <router-view @closeDrawer="drawer = true"></router-view>
    </v-main>
    <div v-for="(snack, i) in alertsTop" :key="i">
      <v-snackbar
        v-if="snack"
        v-model="snack.show"
        class="status"
        :timeout="2000"
        :class="'status-' + snack.type"
        top
        color="#424658f1"
      >
        <div class="d-flex">
          <v-icon class="ml-1 mr-3">
            {{ snack.icon }}
          </v-icon>
          <div
            style="min-width: 27em"
            :class="snack.message === ' ' && 'd-flex align-center'"
          >
            <div class="alert-title notification-font-title">
              <span>{{ $localize(snack.title) }}</span>
              <v-btn icon small @click="closeSnackbar(snack)">
                <v-icon color="black"> mdi-close </v-icon>
              </v-btn>
            </div>
            <div
              class="alert-text notification-font-text"
              v-html="
                snack.message
                  ? snack.message
                  : snack.type === 'error'
                  ? $localize(
                      'Oops_something_went_wrong_please_try_again_later'
                    )
                  : ''
              "
            ></div>
          </div>
        </div>
      </v-snackbar>
    </div>
    <!-- <mobile-navigation v-model="drawer_mobile" /> -->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex"
import TheAccount from "@/components/app/TheAccount"
import TheNav from "@/components/app/vertical/TheNav"

export default {
  name: "MainLayout",
  // eslint-disable-next-line vue/no-unused-components
  components: { TheAccount, TheNav },
  data() {
    return {
      date: new Date().valueOf(),
      drawer_mobile: false,
      drawer: false,
      error: false,
      secondaryNavs: [],
      alertsRigth: [],
      alertsTop: [],
    }
  },
  computed: {
    ...mapGetters("app", { profile: "getProfile" }),
    ...mapGetters(["getAlert", "isInited"]),
    ...mapGetters("secondaryNavs", { getSecondaryNavs: "getSecondaryNavs" }),
    ...mapGetters("app", { getMobileDrawer: "getMobileDrawer" }),
    ...mapGetters("app", { getAuxilarium: "getAuxilarium" }),
    ...mapGetters("app", { getSubNavigation: "getSubNavigation" }),
    ...mapGetters("app", { getSubNavigationOpen: "getSubNavigationOpen" }),
    ...mapGetters("app", { getDetail: "getDetail" }),
    ...mapGetters("app", { getDetailOpen: "getDetailOpen" }),
  },
  watch: {
    $route() {
      this.drawer_mobile = false
    },
    drawer(newData) {
      if (newData) this.drawer_mobile = false
    },
    drawer_mobile(newData) {
      if (newData) this.drawer = false
    },
    getMobileDrawer(value) {
      this.drawer_mobile = value
    },
    getAlert(alerts) {
      let array = []
      if (alerts.length) {
        array = alerts.filter((x) => x.show && x.position)
        if (alerts.length > 9)
          for (let i = 0; i < array.length - 9; i++) array[i].show = false
        this.alertsRigth = array.filter((x) => x.show)
        let filter = alerts.filter((x) => x.show && !x.position).at(-1)
        if (filter) this.alertsTop = [filter]
      }
    },
    getSecondaryNavs(value) {
      this.secondaryNavs = value
    },
  },
  created() {
    setInterval(() => {
      this.date += 1000
    }, 1000)
  },
  methods: {
    logout() {
      return this.$store.dispatch("app/setToken", null)
    },
    goToElem(item) {
      this.$store.dispatch("secondaryNavs/setGotoSecondaryNavs", item.id)
    },
    openDrawer() {
      if (this.getSubNavigation && !this.getSubNavigationOpen)
        this.$store.dispatch("app/setSubNavigationOpen", true)
      else if (this.getDetail && this.getDetailOpen)
        this.$store.dispatch("app/setDetailOpen", false)
      else {
        // if (this.getSubNavigationOpen)
        //   this.$store.dispatch("app/setSubNavigationOpen", false)
        this.drawer_mobile = !this.drawer_mobile
      }
    },
    toUser() {
      if (this.$route.name !== "user") {
        this.$router.push("/user")
      }
    },
    closeSnackbar(snack) {
      snack.show = false
    },
  },
}
</script>
