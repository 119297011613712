<template>
  <v-dialog
    :value="value"
    width="100%"
    max-width="850"
    transition="scroll-x-reverse-transition"
    scrollable
    @input="(e) => $emit('input', e)"
    @click:outside="$emit('input', false)"
  >
    <v-card color="bg">
      <v-card-title>
        {{ $localize("profile") }}
        <v-spacer />
        <v-tooltip bottom center>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="d-none d-md-flex"
              icon
              v-on="on"
              @click="$emit('input', false)"
            >
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>{{ $localize("close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <MasterElement>
          <ScrollShadowElement>
            <v-card>
              <v-card-text>
                <div class="d-flex">
                  <ProfileAvatar
                    :img="item.avatar_path"
                    :title="item.full_name"
                    size="64"
                    class="mr-12"
                  />
                  <v-row>
                    <v-col cols="6">
                      <static-subtext>
                        {{ $localize("first_name") }}
                      </static-subtext>
                      <static-text>
                        {{ item.first_name }}
                      </static-text>
                    </v-col>
                    <v-col cols="6">
                      <static-subtext>
                        {{ $localize("last_name") }}
                      </static-subtext>
                      <static-text>
                        {{ item.last_name }}
                      </static-text>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </ScrollShadowElement>
        </MasterElement>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap">
        <v-row class="ma-0">
          <v-spacer />
          <v-btn class="mr-2" color="gray_3" text @click="$emit('edit', true)">
            {{ $localize("edit") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileDialog",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
