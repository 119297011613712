const routes = [
  {
    path: process.env.VUE_APP_LOGIN_PATH || "/login",
    name: "login",
    meta: {
      group: "grid",
      isLogin: true,
      roles: ["TEAMLEAD", "BUYER", "PRODUCT"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/login/"),
  },
  {
    path: process.env.VUE_APP_VUE_APP_MAIN_PATH || "/",
    name: "main",
    meta: {
      group: "grid",
      roles: ["TEAMLEAD", "BUYER", "PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/main/"),
  },
  // {
  //   path: process.env.VUE_APP_PROFILE_PATH || "/profile",
  //   name: "profile",
  //   meta: {
  //     // group: "grid",
  //     roles: ["TEAMLEAD", "BUYER", "PRODUCT"],
  //   },
  //   component: () => import("../views/profile/"),
  // },
  {
    path: process.env.VUE_APP_MY_COMMAND_PATH || "/my-command",
    name: "my_command",
    meta: {
      group: "grid",
      roles: ["TEAMLEAD"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/myCommand/"),
  },
  {
    path: process.env.VUE_APP_STATISTICS_PATH || "/statistics",
    name: "statistics",
    meta: {
      group: "grid",
      roles: ["PRODUCT"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/statistics/"),
  },
  {
    path: process.env.VUE_APP_TEAM_STATISTICS_PATH || "/team-statistics",
    name: "team_statistics",
    meta: {
      group: "grid",
      roles: ["TEAMLEAD"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/teamStatistics/"),
  },
  {
    path: process.env.VUE_APP_MY_STATISTICS_PATH || "/my-statistics",
    name: "my_statistics",
    meta: {
      group: "grid",
      roles: ["BUYER"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/myStatistics/"),
  },
  {
    path: process.env.VUE_APP_STREAMS_PATH || "/streams",
    name: "streams",
    meta: {
      group: "grid",

      roles: ["TEAMLEAD", "PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/streams/"),
    redirect:
      (process.env.VUE_APP_STREAMS_PATH || "/active-streams") +
      "/" +
      (process.env.VUE_APP_STREAMS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_STREAMS_ALL_PATH || "all",
        name: "streams_all",
        component: () => import("../views/streams/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_STREAMS_ID_PATH || ":id",
        name: "streams_stream",
        component: () => import("../views/streams/Stream"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_MY_STREAMS_PATH || "/my-streams",
    name: "my_streams",
    meta: {
      group: "grid",
      roles: ["BUYER", "TEAMLEAD"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/myStreams/"),
    redirect:
      (process.env.VUE_APP_MY_STREAMS_PATH || "/my-streams") +
      "/" +
      (process.env.VUE_APP_MY_STREAMS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_MY_STREAMS_ALL_PATH || "all",
        name: "my_streams_all",
        component: () => import("../views/myStreams/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_MY_ID_PATH || ":id",
        name: "my_streams_stream",
        component: () => import("../views/myStreams/Stream"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_OFFERS_PATH || "/offers",
    name: "offers",
    meta: {
      group: "grid",
      roles: ["BUYER", "TEAMLEAD"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/offers/"),
    redirect:
      (process.env.VUE_APP_OFFERS_PATH || "/offers") +
      "/" +
      (process.env.VUE_APP_OFFERS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_OFFERS_ALL_PATH || "all",
        name: "offers_all",
        component: () => import("../views/offers/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_OFFERS_ID_PATH || ":id",
        name: "offers_offer",
        component: () => import("../views/offers/Offer"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_ADD_OFFERS_PATH || "/add-offers",
    name: "add_offers",
    meta: {
      group: "grid",
      roles: ["PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/addOffers/"),
    redirect:
      (process.env.VUE_APP_ADD_OFFERS_PATH || "/add-offers") +
      "/" +
      (process.env.VUE_APP_ADD_OFFERS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_ADD_OFFERS_ALL_PATH || "all",
        name: "add_offers_all",
        component: () => import("../views/addOffers/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_ADD_OFFERS_ID_PATH || ":id",
        name: "add_offers_offer",
        component: () => import("../views/addOffers/Offer"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_S2S_PARTNERS_PATH || "/s2s-partners",
    name: "s2s_partners",
    meta: {
      group: "grid",
      roles: ["PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/s2sPartners/"),
    redirect:
      (process.env.VUE_APP_S2S_PARTNERS_PATH || "/s2s-partners") +
      "/" +
      (process.env.VUE_APP_S2S_PARTNERS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_S2S_PARTNERS_ALL_PATH || "all",
        name: "s2s_partners_all",
        component: () => import("../views/s2sPartners/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_S2S_PARTNERS_ID_PATH || ":id",
        name: "s2s_partners_partner",
        component: () => import("../views/s2sPartners/Partner"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_ADD_PARTNERS_PATH || "/add-partners",
    name: "add_partners",
    meta: {
      group: "grid",
      roles: ["PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/addPartners/"),
    redirect:
      (process.env.VUE_APP_ADD_PARTNERS_PATH || "/add-partners") +
      "/" +
      (process.env.VUE_APP_ADD_PARTNERS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_ADD_PARTNERS_ALL_PATH || "all",
        name: "add_partners_all",
        component: () => import("../views/addPartners/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_ADD_PARTNERS_ID_PATH || ":id",
        name: "add_partners_partner",
        component: () => import("../views/addPartners/Partner"),
        meta: {},
      },
    ],
  },
  {
    path: process.env.VUE_APP_ALL_COMPLAINTS_PATH || "/all-complaints",
    name: "all_complaints",
    meta: {
      group: "grid",
      roles: ["CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/allComplaints/"),
  },
  {
    path: process.env.VUE_APP_COMPLAINTS_PATH || "/complaints",
    name: "complaints",
    meta: {
      // group: "grid",
      roles: ["BUYER", "TEAMLEAD", "PRODUCT", "CEO"],
      icon: "mdi-monitor-dashboard",
    },
    component: () => import("../views/complaints/"),
    redirect:
      (process.env.VUE_APP_COMPLAINTS_PATH || "/complaints") +
      "/" +
      (process.env.VUE_APP_COMPLAINTS_ALL_PATH || "all"),
    children: [
      {
        path: process.env.VUE_APP_COMPLAINTS_ALL_PATH || "all",
        name: "complaints_all",
        component: () => import("../views/complaints/All"),
        meta: {},
      },
      {
        path: process.env.VUE_APP_COMPLAINTS_ID_PATH || ":id",
        name: "complaints_complaint",
        component: () => import("../views/complaints/Complaint"),
        meta: {},
      },
    ],
  },
]

export default {
  get: function () {
    return routes
      .filter((x) => {
        let width = document.querySelector("html").clientWidth
        if (x.meta && x.meta.maxDisplay)
          switch (x.meta.maxDisplay) {
            case "xs":
              return width <= 600
            case "sm":
              return width <= 960
            case "md":
              return width <= 1264
            case "lg":
              return width <= 1904
          }
        else if (x.meta.minDisplay)
          switch (x.meta.minDisplay) {
            case "sm":
              return width > 600
            case "md":
              return width > 960
            case "lg":
              return width > 1264
          }
        return true
      })
      .map((x) => {
        if (x.children && x.children.length) {
          x.meta.children = x.children.filter((x) => !x.redirect)
        }
        return x
      })
  },
  pre: () => routes,
}
